import React, {
  useState,
  useRef,
  MouseEventHandler,
  useEffect,
  useCallback,
} from "react";
import debounce from "lodash/debounce";
import styled from "styled-components";

import useIsMounted from "~/hooks/useIsMounted";

const StyledHiddenScroller = styled.div<{ space: number }>`
  width: 100%;
  overflow: hidden;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        height: ${props.theme
          .space(breakpoint, props.space)
          .toFixed(0)}px;          
    `;
    })}
`;

const StyledHiddenScrollerScrollable = styled.div<{
  isDragging: boolean;
}>`
  width: 100%;
  height: calc(100% + var(--sbw, 20px));
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: ${({ isDragging }) => (isDragging ? "auto" : "smooth")};
  cursor: ${({ isDragging }) =>
    isDragging ? "grabbing !important" : "default"};
  -webkit-overflow-scrolling: touch;

  overscroll-behavior-x: contain;
  scrollbar-width: none;

  & a {
    user-select: none;
    -webkit-user-drag: none;
    cursor: ${({ isDragging }) =>
      isDragging ? "grabbing !important" : "pointer"};
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 1px;
    display: none;
  }
`;

const StyledHiddenScrollerScrollableContent = styled.div`
  display: inline-flex;
  width: auto;
  min-width: 100%;
  background-color: var(--ikon-bg-color, #fff);
  height: 100%;
`;

const Border = styled.div`
  height: 1px;
  background-color: #000;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        margin: 0 ${props.theme.marginPx(breakpoint)};          
      `;
    })}
`;

const movePerTick = 10;
const areaThrottle = 0.5;
const areaWidthPrecentage = 0.125;
const FPS = 1000 / 60;
const mouseOverTime = 300;

export const HiddenVerticalScroller = ({
  children,
  space,
}: {
  children: React.ReactNode;
  space: number;
}) => {
  const isMounted = useIsMounted();

  const isDraggingRef = useRef(false);
  const mouseNavActive = useRef(false);
  const dragStartXRef = useRef<number>(0);
  const mouseOverTimeCounterRef = useRef<number>(0);
  const animationFrameStartRef = useRef<number | null>(0);
  const dragDisplacementRef = useRef<number>(0);
  const scrollerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const mouseXPositionRef = useRef(0);
  const dragDelayTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const animationFrameRef = useRef<ReturnType<
    typeof requestAnimationFrame
  > | null>(null);

  const [isDragging, setIsDragging] = useState(false);
  
  const scrollContainer = (timestamp: number) => {
    if (!isMounted) return;
    if (isDraggingRef.current) return;

    if (!animationFrameStartRef.current)
      animationFrameStartRef.current = timestamp;

    const elapsed = timestamp - animationFrameStartRef.current;

    const area = Math.max(
      document.documentElement.clientWidth * areaWidthPrecentage,
      100
    );

    if (mouseXPositionRef.current < area) {
      mouseOverTimeCounterRef.current += elapsed;
      if (
        mouseOverTimeCounterRef.current > mouseOverTime &&
        scrollerRef.current.scrollLeft > 0
      ) {
        if (scrollerRef.current.style.scrollBehavior !== "auto")
          scrollerRef.current.style.scrollBehavior = "auto";

        scrollerRef.current.scrollLeft -=
          movePerTick *
          (1 - (0.5 * mouseXPositionRef.current) / area) *
          (elapsed / FPS);
      }
    } else if (
      mouseXPositionRef.current >
      document.documentElement.clientWidth - area
    ) {
      mouseOverTimeCounterRef.current += elapsed;
      if (
        mouseOverTimeCounterRef.current > mouseOverTime &&
        scrollerRef.current.scrollLeft <=
          scrollerRef.current.scrollWidth -
            document.documentElement.clientWidth +
            2
      ) {
        if (scrollerRef.current.style.scrollBehavior !== "auto")
          scrollerRef.current.style.scrollBehavior = "auto";
        scrollerRef.current.scrollLeft +=
          ((movePerTick *
            (mouseXPositionRef.current +
              area * areaThrottle -
              (document.documentElement.clientWidth - area))) /
            area) *
          (elapsed / FPS);
      }
    } else {
      mouseOverTimeCounterRef.current = 0;
      if (scrollerRef.current.style.scrollBehavior === "auto") {
        scrollerRef.current.style.scrollBehavior = "";
      }
    }
    animationFrameRef.current = requestAnimationFrame(scrollContainer);
    animationFrameStartRef.current = timestamp;
  };

  const handleOnPointerUp = (immediately: boolean) => {
    if (!isMounted) return;

    if (immediately) {
      if (dragDelayTimeoutRef.current)
        clearTimeout(dragDelayTimeoutRef.current);
      setIsDragging(false);
      dragDisplacementRef.current = 0;
      isDraggingRef.current = false;
    } else {
      dragDelayTimeoutRef.current = setTimeout(() => {
        if (isMounted) {
          setIsDragging(false);
          dragDisplacementRef.current = 0;
          dragDelayTimeoutRef.current = null;
          isDraggingRef.current = false;
        }
      }, 60);
    }
  };

  const onMouseEnterAnimationFrame = () => {
    if (!isMounted) return;

    if (mouseNavActive.current && !isDraggingRef.current) {
      mouseOverTimeCounterRef.current = 0;
      animationFrameStartRef.current = null;
      animationFrameRef.current = requestAnimationFrame(scrollContainer);
    }    
  };

  const onMouseLeave = () => {
    if (!isMounted) return;

    if (mouseNavActive.current) {
      if (animationFrameRef.current) {
        mouseOverTimeCounterRef.current = 0;
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
    }
    handleOnPointerUp(true);
  };

  const onMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isMounted) return;
    dragDisplacementRef.current = 0;

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
      mouseOverTimeCounterRef.current = 0;
    }

    dragStartXRef.current = event.clientX;
    isDraggingRef.current = true;
    setIsDragging(true);
  };

  const onMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isMounted || !scrollerRef.current) return;

    mouseXPositionRef.current = event.clientX;

    if (isDraggingRef.current) {
      const mouseX = event.clientX;

      const moveBy = dragStartXRef.current - mouseX;
      dragDisplacementRef.current += Math.abs(moveBy);

      scrollerRef.current.scrollLeft = scrollerRef.current.scrollLeft + moveBy;

      dragStartXRef.current = mouseX;
    }
  };

  const onResize = useCallback(() => {
    if (!isMounted || !scrollerRef.current) return;

    mouseNavActive.current =
      scrollerRef.current.scrollWidth > document.documentElement.clientWidth;
  }, [isMounted]);

  const onResizeDebounced = debounce(
    onResize,
    Math.random() * (350 - 200) + 200
  );

  useEffect(() => {
    if (typeof window !== "undefined") onResize();
    window.addEventListener("resize", onResizeDebounced);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      window.removeEventListener("resize", onResizeDebounced);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledHiddenScroller space={space}>
      <Border />
      <StyledHiddenScrollerScrollable
        ref={scrollerRef}
        isDragging={isDragging}
        onClickCapture={(event) => {
          if (dragDisplacementRef.current > 10) {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
        onMouseUp={(event) => {
          handleOnPointerUp(false);
          if (
            window.scrollY + event.clientY >= scrollerRef.current.offsetTop &&
            window.scrollY + event.clientY <=
              scrollerRef.current.offsetTop + scrollerRef.current.offsetHeight
          ) {
            setTimeout(() => {
              if (isMounted)
                onMouseEnterAnimationFrame();
            }, 100);            
          }
        }}
        onMouseDown={onMouseDown}
        onMouseEnter={() => {
          onMouseEnterAnimationFrame();
         
          handleOnPointerUp(true);
        }}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        <StyledHiddenScrollerScrollableContent>
          {children}
        </StyledHiddenScrollerScrollableContent>
      </StyledHiddenScrollerScrollable>
    </StyledHiddenScroller>
  );
};

export default HiddenVerticalScroller;
