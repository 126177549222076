import React from "react";
import styled from "styled-components";
import Link from "next/link";
import { Heading } from "../ui/Heading";
import { ApiImage } from "../ui/ApiImage";
import { formatDate } from "~/utils";
import { useConfigContext } from "~/providers/ConfigContextProvider";
import LinkOverlay from "../styled/LinkOverlay";
import TextWithArrow from "../ui/TextWithArrow";
import SafeHtmlSpan from "../ui/SafeHtmlSpan";
import { themeImgSizes } from "~/theme";
import HiddenVerticalScroller from "../ui/HiddenVerticalScroller";

const imgSizes = {
  screen: "15vw",
  desktop: "20vw",
  tabletLandscape: "33.33vw",
  tablet: "50vw",
  base: "100vw",
};

const Article = styled.article<{ hasSubtitle: boolean; hasImage: boolean }>`
  display: inline-flex;
  width: auto;
  min-width: 100%;
  height: 100%;
  position: relative;

  & .arrow {
    display: none;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        column-gap:  ${props.theme.spacePx(breakpoint, 8)};
        padding: 0 ${props.theme.marginPx(breakpoint)};
        `;
    })}

  ${({ theme }) => theme.breakpoints.tablet} {
    & .arrow {
      display: block;
      opacity: 0;
      transition: opacity 0.3s;
    }

    @media (any-pointer: fine) {
      &:hover {
        .arrow {
          opacity: 1;
        }
      }
    }
  }

  & .apiimage.loaded {
    img {
      transition: filter 0.125s, opacity 0.5s;
    }
  }

  @media (any-pointer: fine) {
    &:hover {
      .apiimage.loaded .aspect-ratio {
        background-color: var(--ikon-hl-color, #ff0);

        img {
          filter: grayscale(1);
          mix-blend-mode: multiply;
        }
      }

      .arrow {
        opacity: 1;
      }
    }
  }
`;

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  display: inline-flex;
  min-width: 100%;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        column-gap:  ${props.theme.spacePx(breakpoint, 8)};
        `;
    })}
`;

const Img = styled.div<{
  space: number;
  aspectRatio: number;
}>`
  grid-area: img;
  overflow: hidden;
  height: 100%;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        width: ${(
          props.theme.space(breakpoint, props.space) * props.aspectRatio
        ).toFixed(4)}px;
      `;
    })}
`;

const Text = styled.div`
  grid-area: text;
  flex-grow: 2;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        padding-top: ${props.theme.spacePx(breakpoint, 9)};
      `;
    })}
`;

const ArticleHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;

  & .arrow {
    position: sticky;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        column-gap:  ${props.theme.spacePx(breakpoint, 8)};
        & .arrow {
          right: ${props.theme.marginPx(breakpoint)};
        }
      `;
    })}
`;

const Border = styled.div`
  height: 1px;
  background-color: #000;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        margin: 0 ${props.theme.marginPx(breakpoint)};          
      `;
    })}
`;

export const ListItemWhatsOn = ({
  item,
  isLast,
  archiveInfo,
}: {
  item: any;
  isLast?: boolean;
  archiveInfo?: React.ReactNode;
}) => {
  const config = useConfigContext();

  const formattedDateBegin = formatDate(item?.acf?.date_begin);
  const formattedDateEnd = formatDate(item?.acf?.date_end);

  let infoLine = [];

  if (item?.taxonomies?.programme_category?.length > 0)
    infoLine = item?.taxonomies?.programme_category
      .filter(
        (term: any) =>
          term.slug !== "event" &&
          term.slug !== "exhibitions" &&
          term.slug !== "exhibition"
      )
      .map((term: any) => term.name);
  if (item?.type === "event" && infoLine.length === 0) infoLine.push("Event");

  if (item?.type === "exhibition") infoLine.push("Exhibition");

  let timeInfo = <></>;

  if (formattedDateBegin && item?.acf?.dates?.length === 1)
    timeInfo = (
      <>
        {" "}
        / {item?.acf?.dates?.[0]?.time_begin}&ndash;
        {item?.acf?.dates?.[0]?.time_end}
      </>
    );

  const hasArtistName = item?.acf?.artist_name?.trim();
  const hasSubtitle = item?.acf?.sub_title?.trim() || hasArtistName;
  const title =
    hasArtistName && item?.type === "exhibition"
      ? item?.acf?.artist_name
      : item?.title;

  const isLarge = hasSubtitle && item?.type === "exhibition";
  return (
    <>
      <HiddenVerticalScroller space={isLarge ? 3 : 4}>
        <Article hasSubtitle={isLarge} hasImage={item?.featuredImage?.sizes}>
          <Wrapper>
            {item?.featuredImage?.sizes && (
              <Img
                space={isLarge ? 3 : 4}
                aspectRatio={
                  item?.featuredImage?.width / item?.featuredImage?.height
                }
              >
                <ApiImage
                  alt=""
                  imgSizes={themeImgSizes(imgSizes)}
                  sizes={item?.featuredImage?.sizes}
                  mode="fill"
                  objectPosition={item?.featuredImage?.cropPosition}
                />
              </Img>
            )}
            <Text>
              <ArticleHeader>
                <Heading heading="h4" space={8} noWrap>
                  <SafeHtmlSpan html={infoLine.join("/")} />
                  &emsp;
                  <span style={{ fontWeight: 400 }}>
                    {formattedDateBegin}

                    {formattedDateEnd &&
                      formattedDateBegin !== formattedDateEnd && (
                        <>
                          {formattedDateBegin && formattedDateEnd && (
                            <>&ndash;</>
                          )}
                          {formattedDateEnd}
                        </>
                      )}
                    {timeInfo}
                  </span>
                </Heading>

                <Heading heading="h4" space={8} className="arrow">
                  <TextWithArrow align="right" heading="h4" size={9}>
                    SEE More
                  </TextWithArrow>
                </Heading>
              </ArticleHeader>
              <Link
                passHref
                href={`${config.baseUrl}/${item.type}/${item?.slug}`}
              >
                <LinkOverlay>
                  <Heading
                    heading={isLarge ? "h1" : "h2"}
                    space={hasSubtitle && !isLarge ? 9 : 8}
                    html={title}
                    noWrap
                  />
                </LinkOverlay>
              </Link>
              {hasSubtitle && (
                <Heading
                  heading={isLarge ? "h2" : "h4"}
                  fontWeight={!isLarge ? "400" : undefined}
                  space={8}
                  html={item?.acf?.sub_title ?? item?.title}
                  noWrap
                />
              )}
              {archiveInfo}
            </Text>
          </Wrapper>
        </Article>
      </HiddenVerticalScroller>
      {isLast && <Border />}
    </>
  );
};
