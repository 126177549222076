import React from "react";
import styled from "styled-components";
import { ListItemWhatsOn } from "./ListItemWhatsOn";

const Listing = styled.div<{ isLoading: boolean; spaceBottom?: number }>`
  width: 100%;
  transition: opacity 0.3s;

  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};

  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "all")};
  touch-action: ${({ isLoading }) => (isLoading ? "none" : "auto")};

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        margin-bottom: ${props.theme.spacePx(
          breakpoint,
          props?.spaceBottom ?? 4
        )};
        `;
    })}
`;

export const WhatsOnListing = ({
  items,
  keyPrefix,
  heading,
  isLoading,
  pagination,
  spaceBottom,
  renderArchiveInfo,
}: {
  items: any;
  isLoading?: boolean;
  spaceBottom?: number;
  keyPrefix: string;
  heading?: React.ReactNode;
  pagination?: React.ReactNode;
  renderArchiveInfo?: (item: any, index: number) => React.ReactNode;
}) => {
  if (!items?.length) return <></>;
  return (
    <Listing isLoading={!!isLoading} spaceBottom={spaceBottom}>
      {heading}
      {items?.map((item: any, index: number) => (
        <ListItemWhatsOn
          item={item}
          key={`${keyPrefix}-${index + 1}`}
          isLast={index === items.length - 1}
          archiveInfo={
            typeof renderArchiveInfo === "function"
              ? renderArchiveInfo(item, index)
              : undefined
          }
        />
      ))}
      {pagination}
    </Listing>
  );
};

export default WhatsOnListing;
